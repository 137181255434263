<template>
	<v-container fluid class="fill-height" :class="affichagePortable ? 'pa-0' : ''">
		<v-row style="height: 100%" class="ma-0" v-if="messagesNonLus.length > 0" >
			<v-col class="pa-0" justify="start">
				<v-list class="pa-0">
					<template v-for="(message, index) in messagesNonLus">
						<v-divider v-if="index > 0" :key="index"></v-divider>
						<v-list-item :key="message.id" :style="'background-color: '+getIconCouleur(message.type).couleur">
							<v-list-item-avatar tile>
								<v-icon size="40">{{getIconCouleur(message.type).icone}}</v-icon>
							</v-list-item-avatar>
							<v-list-item-content class="pa-0 pb-2">
								<v-row class="ma-0">
									<v-col class="pa-0">
										<v-row class="ma-0">
											<div class="text-overline deep-purple--text">{{formatDate(message.created_at)}}</div>
											<v-spacer/>
											<v-btn icon x-small @click="onClickSupprMessage(message.id)" class="mt-2" color="grey">
												<v-icon>mdi-delete</v-icon>
											</v-btn>
										</v-row>
										
										<div class="font-weight-bold text-subtitle-1">{{message.data.titre}}</div>
										<div class="font-weight-light text-subtitle-2">{{message.data.message}}</div>
									</v-col>
								</v-row>
								<!-- <v-list-item-title v-html="message.data.titre"></v-list-item-title>
								<v-list-item-subtitle v-html="message.data.message"></v-list-item-subtitle> -->
							</v-list-item-content>
						</v-list-item>
					</template>
				</v-list>
			</v-col>
		</v-row>	
		<v-row v-else justify="center">
			<div>Aucun message</div>
		</v-row>
	</v-container>
</template>

<script>
import axios from 'axios'
    import { required } from 'vuelidate/lib/validators'
	import moment from 'moment'
	moment.locale('fr')
    export default {
        created() {
			this.$store.commit('majTitrePage', 'Messages')
	
        },
        mounted() {
			this.$store.dispatch('getMessagesNonLus', this.$store.getters.membreCourant.id_user)
			
        },
        data() {
			return {
				
                
            }
		},
		computed: {
			affichagePortable() {
				return this.$vuetify.breakpoint.name == 'xs'
			},
			messagesNonLus() {
				return this.$store.getters.messagesNonLus
			},
			
		},
		methods: {
			formatDate(date) {
				if(!date) return ''
				return moment(date).format('DD/MM/YYYY HH:mm')
			},
			getIconCouleur(type) {
				// retourne une icone et une couleur selon le type de notification
				let couleur = ''
				let	icone = 'mdi-email'
				
				switch (type) {
					case 'App\\Notifications\\NotificationChangementPlanning':
						couleur = '#EEEEEE'
						icone = 'mdi-calendar-alert'
						break;
					case 'App\\Notifications\\NotificationAnnulationGarde':
						couleur = '#EF9A9A'
						icone = 'mdi-calendar-remove'
						break;
					case 'App\\Notifications\\NotificationAttributionGarde':
						couleur = '#C5E1A5'
						icone = 'mdi-calendar-plus'
						break;
					case 'App\\Notifications\\NotificationDiffusionPlanning':
						couleur = '#EEEEEE'
						icone = 'mdi-cloud'
						break;
					case 'App\\Notifications\\NotificationRappelDispos':
						couleur = '#EEEEEE'
						icone = 'mdi-alert-circle'
						break;
					case 'App\\Notifications\\NotificationRappelManoeuvre':
						couleur = '#EEEEEE'
						icone = 'mdi-alert-circle'
						break;
				
					default:
						break;
				}
				return {
					couleur: couleur,
					icone: icone
				}
			},
			onClickSupprMessage(id_message) {
				axios.post('api/user/delete_message', { 
					id: id_message
					})
					.then((reponse) => {
						this.$store.dispatch('getMessagesNonLus', this.$store.getters.membreCourant.id_user)
					})
					.catch((error) => {
						
					})
					.then(() => {
						
					})
			},
        },
        validations: {
            
		},
		watch: {
			
		}
  	}
</script>

<style>
#tabs_messages .v-tabs-items {
    background-color: transparent !important;
}

.timeline_message .v-timeline-item__divider {
	padding-top: 25px !important;
}

</style>

<style scoped>

.zone_timeline {
	max-height: calc(100vh - 230px);
	min-height: calc(100vh - 230px);
	overflow-y: auto;
}

.zone_timeline_desktop {
	max-height: calc(100vh - 200px);
	min-height: calc(100vh - 200px);
	overflow-y: auto;
}

.v-card__text, .v-card__title {
  word-break: normal; /* maybe !important  permet de ne pas couper les mots lors des retours à la ligne*/
}

</style>